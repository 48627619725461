import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import sk from 'vuetify/lib/locale/sk';
/*
import  {
  VSelect,
} from 'vuetify/lib/components'
*/
Vue.use(Vuetify);

export default new Vuetify({
    /*components:{
      VSelect
    },*/
    theme:{
      themes:{
        light:{
          primary: "#B11116"
        }
      }
    },
    lang: {
      locales: { sk },
      current: 'sk',
    },
});
