var filters = {
    S1a: [
        {"label": "Verejná správa", number: 1},
        /*{"label": "Samospráva", number: 2},*/
        {"label": "Občiansky sektor", number: 3},
        {"label": "Biznis (vrátane sociálnych podnikov)", number: 4},
        {"label": "Na voľnej nohe", number: 5},
        {"label": "Akademický sektor", number: 7},
        /*{"label": "Verejná správa", number: 15},*/
        {"label": "Iné", number: 98},
    ],
    dlhodobaOblast:[
        {label:"Posilňovanie demokracie",number:1},
        {label:"Regionálny rozvoj",number:2},
        {label:"Kultúra",number:3},
        {label:"Zdravý životný štýl",number:4},
        {label:"Prístup k zdravotnej starostlivosti",number:5},
        {label:"Ochrana životného prostredia",number:6},
        {label:"Vzdelávanie",number:7},
        {label:"Sociálno-ekonomická inklúzia",number:8},
        {label:"Poľnohospodárstvo a vodné hospodárstvo",number:9},
        {label:"Architektúra a urbanizmus",number:10},
        {label:"Výskum a inovácie",number:11},
        {label:"Iné",number:98},
      ],
    dopad:[
        {label:"Miestny",number:1},
        {label:"Regionálny",number:2},
        {label:"Národný",number:3},
        {label:"Európsky",number:4},
        {label:"Globálny",number:5},
    ],
    lokalita:[
        {label:"Bratislavský kraj",number:1},
        {label:"Trnavský kraj",number:2},
        {label:"Trenčiansky kraj",number:3},
        {label:"Nitriansky kraj",number:4},
        {label:"Žilinský kraj",number:5},
        {label:"Banskobystrický kraj",number:6},
        {label:"Prešovský kraj",number:7},
        {label:"Košický kraj",number:8},
        {label:"zahraničie",number:99},
    ],
    pohlavie:[
        {label:"Muž",number:1},
        {label:"Žena",number:2},
        //{label:"non-binary",number:3},
        //{label:"transgender",number:4},
        //{label:"iné",number:98},
        {label:"Neuvedené",number:99},
    ],
    vek:[
        //{label:"menej ako 18 rokov",number:1},
        {label:"18 - 24 rokov",number:2},
        {label:"25 - 34 rokov",number:3},
        {label:"35 - 44 rokov",number:4},
        {label:"45 - 54 rokov",number:5},
        {label:"55 a viac rokov",number:6},
        {label:"Neuvedené",number:99},
    ],
    rola:[
        {label:"Zakladateľ/ka",number:1,sex:{1:"zakladateľ",2:"zakladateľka",99:"zakladateľ/ka"}},
        {label:"Riaditeľ/ka",number:2,sex:{1:"riaditeľ",2:"riaditeľka",99:"riaditeľ/ka"}},
        {label:"Zamestnanec/kyňa",number:3,sex:{1:"zamestnanec",2:"zamestnankyňa",99:"zamestnanec/kyňa"}},
        {label:"Poskytuje finančnú podporu",number:4},
        {label:"Poskytuje expertné know-how a nefinančnú podporu",number:5},
        {label:"Výskumný pracovník/pracovníčka",number:6,sex:{1:"výskumný pracovník",2:"výskumná pracovníčka",99:"výskumný pracovník/pracovníčka"}},
        {label:"Podieľa sa priamo na tvorbe legislatívy a verejných politík",number:7},
        {label:"Novinár/ka",number:8,sex:{1:"novinár",2:"novinárka",99:"novinár/ka"}},
        {label:"Iné",number:98},
        {label:"Neuvedené",number:99},
    ],

}

export default filters;