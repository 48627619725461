var data = {
    nodes: [],
    edges:[],
    getEdges: ()=>{},
    getNodes: ()=>{},
}
data.nodes = [
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n1_1",
            "name": "O\u013dANO",
            "img": "1.png",
            "nodeSize": 643.4782608695654,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -14769.44999012051,
            "y": -471.1060813041675
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n2_1",
            "name": "SMER-SD",
            "img": "2.png",
            "nodeSize": 1504.3478260869567,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 17499.00362108327,
            "y": 453.6637681544945
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n3_1",
            "name": "Kotlebovci",
            "img": "3.png",
            "nodeSize": 226.08695652173915,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 12813.534518753417,
            "y": 90.19746787449454
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n4_1",
            "name": "SME RODINA",
            "img": "4.png",
            "nodeSize": 747.8260869565217,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -4530.97898630872,
            "y": -211.9291950290475
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n5_1",
            "name": "Progres\u00edvne Slovensko",
            "img": "5.png",
            "nodeSize": 991.3043478260871,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -19703.87141283279,
            "y": 3420.2970998240044
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n6_1",
            "name": "SaS",
            "img": "6.jpg",
            "nodeSize": 478.2608695652175,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -13221.43727965519,
            "y": 3591.4582215095616
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n7_1",
            "name": "ZA \u013dUD\u00cd",
            "img": "7.png",
            "nodeSize": 200.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -9079.50007974395,
            "y": 1623.479078347186
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n8_1",
            "name": "KDH",
            "img": "8.jfif",
            "nodeSize": 486.9565217391305,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -8086.419864651928,
            "y": -559.1758982376233
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n9_1",
            "name": "Aliancia \u2013 Sz\u00f6vets\u00e9g",
            "img": "9.jpg",
            "nodeSize": 356.52173913043475,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -7777.982559188264,
            "y": -12424.00425374107
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n10_1",
            "name": "Slovensk\u00e1 n\u00e1rodn\u00e1 strana",
            "img": "10.png",
            "nodeSize": 278.26086956521743,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 11255.401097884705,
            "y": 549.1997438461553
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n11_1",
            "name": "Republika",
            "img": "11.png",
            "nodeSize": 713.0434782608695,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 21489.963036692137,
            "y": 399.5937736183136
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n12_1",
            "name": "HLAS-SD",
            "img": "12.png",
            "nodeSize": 1652.1739130434785,
            "onlyStrana": true,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 6501.715570438969,
            "y": 950.9951011924238
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n1_0",
            "name": "O\u013dANO",
            "img": "1.png",
            "nodeSize": 643.4782608695654,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -14769.44999012051,
            "y": -471.1060813041675
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n2_0",
            "name": "SMER-SD",
            "img": "2.png",
            "nodeSize": 1504.3478260869567,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 17499.00362108327,
            "y": 453.6637681544945
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n3_0",
            "name": "Kotlebovci",
            "img": "3.png",
            "nodeSize": 226.08695652173915,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 12813.534518753417,
            "y": 90.19746787449454
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n4_0",
            "name": "SME RODINA",
            "img": "4.png",
            "nodeSize": 747.8260869565217,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -4530.97898630872,
            "y": -211.9291950290475
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n5_0",
            "name": "Progres\u00edvne Slovensko",
            "img": "5.png",
            "nodeSize": 991.3043478260871,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -19703.87141283279,
            "y": 3420.2970998240044
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n6_0",
            "name": "SaS",
            "img": "6.jpg",
            "nodeSize": 478.2608695652175,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -13221.43727965519,
            "y": 3591.4582215095616
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n7_0",
            "name": "ZA \u013dUD\u00cd",
            "img": "7.png",
            "nodeSize": 200.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -9079.50007974395,
            "y": 1623.479078347186
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n8_0",
            "name": "KDH",
            "img": "8.jfif",
            "nodeSize": 486.9565217391305,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -8086.419864651928,
            "y": -559.1758982376233
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n9_0",
            "name": "Aliancia \u2013 Sz\u00f6vets\u00e9g",
            "img": "9.jpg",
            "nodeSize": 356.52173913043475,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": -7777.982559188264,
            "y": -12424.00425374107
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n10_0",
            "name": "Slovensk\u00e1 n\u00e1rodn\u00e1 strana",
            "img": "10.png",
            "nodeSize": 278.26086956521743,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 11255.401097884705,
            "y": 549.1997438461553
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n11_0",
            "name": "Republika",
            "img": "11.png",
            "nodeSize": 713.0434782608695,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 21489.963036692137,
            "y": 399.5937736183136
        }
    },
    {
        "group": "nodes",
        "classes": [
            "strana"
        ],
        "pannable": true,
        "data": {
            "id": "n12_0",
            "name": "HLAS-SD",
            "img": "12.png",
            "nodeSize": 1652.1739130434785,
            "onlyStrana": false,
            "toHide": 0
        },
        "renderedPosition": {
            "x": 6501.715570438969,
            "y": 950.9951011924238
        }
    }
]

data.edges = [
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n2_1",
            "source": "n1_1",
            "target": "n2_1",
            "value": 6.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n3_1",
            "source": "n1_1",
            "target": "n3_1",
            "value": 6.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n4_1",
            "source": "n1_1",
            "target": "n4_1",
            "value": 64.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n5_1",
            "source": "n1_1",
            "target": "n5_1",
            "value": 25.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n6_1",
            "source": "n1_1",
            "target": "n6_1",
            "value": 14.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n7_1",
            "source": "n1_1",
            "target": "n7_1",
            "value": 69.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n8_1",
            "source": "n1_1",
            "target": "n8_1",
            "value": 39.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n9_1",
            "source": "n1_1",
            "target": "n9_1",
            "value": 15.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n10_1",
            "source": "n1_1",
            "target": "n10_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n11_1",
            "source": "n1_1",
            "target": "n11_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_1-n12_1",
            "source": "n1_1",
            "target": "n12_1",
            "value": 13.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n1_1",
            "source": "n2_1",
            "target": "n1_1",
            "value": 6.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n3_1",
            "source": "n2_1",
            "target": "n3_1",
            "value": 30.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n4_1",
            "source": "n2_1",
            "target": "n4_1",
            "value": 25.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n5_1",
            "source": "n2_1",
            "target": "n5_1",
            "value": 8.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n6_1",
            "source": "n2_1",
            "target": "n6_1",
            "value": 15.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n7_1",
            "source": "n2_1",
            "target": "n7_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n8_1",
            "source": "n2_1",
            "target": "n8_1",
            "value": 19.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n9_1",
            "source": "n2_1",
            "target": "n9_1",
            "value": 8.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n10_1",
            "source": "n2_1",
            "target": "n10_1",
            "value": 56.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n11_1",
            "source": "n2_1",
            "target": "n11_1",
            "value": 51.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_1-n12_1",
            "source": "n2_1",
            "target": "n12_1",
            "value": 68.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n1_1",
            "source": "n3_1",
            "target": "n1_1",
            "value": 31.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n2_1",
            "source": "n3_1",
            "target": "n2_1",
            "value": 47.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n4_1",
            "source": "n3_1",
            "target": "n4_1",
            "value": 31.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n5_1",
            "source": "n3_1",
            "target": "n5_1",
            "value": 20.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n6_1",
            "source": "n3_1",
            "target": "n6_1",
            "value": 17.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n7_1",
            "source": "n3_1",
            "target": "n7_1",
            "value": 25.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n8_1",
            "source": "n3_1",
            "target": "n8_1",
            "value": 23.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n9_1",
            "source": "n3_1",
            "target": "n9_1",
            "value": 14.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n10_1",
            "source": "n3_1",
            "target": "n10_1",
            "value": 36.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n11_1",
            "source": "n3_1",
            "target": "n11_1",
            "value": 58.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_1-n12_1",
            "source": "n3_1",
            "target": "n12_1",
            "value": 34.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n1_1",
            "source": "n4_1",
            "target": "n1_1",
            "value": 45.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n2_1",
            "source": "n4_1",
            "target": "n2_1",
            "value": 30.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n3_1",
            "source": "n4_1",
            "target": "n3_1",
            "value": 18.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n5_1",
            "source": "n4_1",
            "target": "n5_1",
            "value": 29.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n6_1",
            "source": "n4_1",
            "target": "n6_1",
            "value": 38.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n7_1",
            "source": "n4_1",
            "target": "n7_1",
            "value": 48.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n8_1",
            "source": "n4_1",
            "target": "n8_1",
            "value": 38.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n9_1",
            "source": "n4_1",
            "target": "n9_1",
            "value": 22.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n10_1",
            "source": "n4_1",
            "target": "n10_1",
            "value": 29.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n11_1",
            "source": "n4_1",
            "target": "n11_1",
            "value": 19.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_1-n12_1",
            "source": "n4_1",
            "target": "n12_1",
            "value": 54.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n1_1",
            "source": "n5_1",
            "target": "n1_1",
            "value": 22.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n2_1",
            "source": "n5_1",
            "target": "n2_1",
            "value": 3.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n3_1",
            "source": "n5_1",
            "target": "n3_1",
            "value": 1.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n4_1",
            "source": "n5_1",
            "target": "n4_1",
            "value": 18.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n6_1",
            "source": "n5_1",
            "target": "n6_1",
            "value": 60.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n7_1",
            "source": "n5_1",
            "target": "n7_1",
            "value": 41.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n8_1",
            "source": "n5_1",
            "target": "n8_1",
            "value": 25.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n9_1",
            "source": "n5_1",
            "target": "n9_1",
            "value": 13.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n10_1",
            "source": "n5_1",
            "target": "n10_1",
            "value": 5.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n11_1",
            "source": "n5_1",
            "target": "n11_1",
            "value": 3.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_1-n12_1",
            "source": "n5_1",
            "target": "n12_1",
            "value": 17.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n1_1",
            "source": "n6_1",
            "target": "n1_1",
            "value": 24.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n2_1",
            "source": "n6_1",
            "target": "n2_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n3_1",
            "source": "n6_1",
            "target": "n3_1",
            "value": 7.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n4_1",
            "source": "n6_1",
            "target": "n4_1",
            "value": 33.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n5_1",
            "source": "n6_1",
            "target": "n5_1",
            "value": 64.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n7_1",
            "source": "n6_1",
            "target": "n7_1",
            "value": 43.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n8_1",
            "source": "n6_1",
            "target": "n8_1",
            "value": 23.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n9_1",
            "source": "n6_1",
            "target": "n9_1",
            "value": 8.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n10_1",
            "source": "n6_1",
            "target": "n10_1",
            "value": 15.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n11_1",
            "source": "n6_1",
            "target": "n11_1",
            "value": 8.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_1-n12_1",
            "source": "n6_1",
            "target": "n12_1",
            "value": 43.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n1_1",
            "source": "n7_1",
            "target": "n1_1",
            "value": 72.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n2_1",
            "source": "n7_1",
            "target": "n2_1",
            "value": 30.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n3_1",
            "source": "n7_1",
            "target": "n3_1",
            "value": 16.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n4_1",
            "source": "n7_1",
            "target": "n4_1",
            "value": 47.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n5_1",
            "source": "n7_1",
            "target": "n5_1",
            "value": 56.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n6_1",
            "source": "n7_1",
            "target": "n6_1",
            "value": 47.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n8_1",
            "source": "n7_1",
            "target": "n8_1",
            "value": 42.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n9_1",
            "source": "n7_1",
            "target": "n9_1",
            "value": 12.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n10_1",
            "source": "n7_1",
            "target": "n10_1",
            "value": 44.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n11_1",
            "source": "n7_1",
            "target": "n11_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_1-n12_1",
            "source": "n7_1",
            "target": "n12_1",
            "value": 47.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n1_1",
            "source": "n8_1",
            "target": "n1_1",
            "value": 42.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n2_1",
            "source": "n8_1",
            "target": "n2_1",
            "value": 14.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n3_1",
            "source": "n8_1",
            "target": "n3_1",
            "value": 8.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n4_1",
            "source": "n8_1",
            "target": "n4_1",
            "value": 35.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n5_1",
            "source": "n8_1",
            "target": "n5_1",
            "value": 21.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n6_1",
            "source": "n8_1",
            "target": "n6_1",
            "value": 22.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n7_1",
            "source": "n8_1",
            "target": "n7_1",
            "value": 42.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n9_1",
            "source": "n8_1",
            "target": "n9_1",
            "value": 10.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n10_1",
            "source": "n8_1",
            "target": "n10_1",
            "value": 26.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n11_1",
            "source": "n8_1",
            "target": "n11_1",
            "value": 10.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_1-n12_1",
            "source": "n8_1",
            "target": "n12_1",
            "value": 22.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n1_1",
            "source": "n9_1",
            "target": "n1_1",
            "value": 22.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n2_1",
            "source": "n9_1",
            "target": "n2_1",
            "value": 24.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n3_1",
            "source": "n9_1",
            "target": "n3_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n4_1",
            "source": "n9_1",
            "target": "n4_1",
            "value": 23.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n5_1",
            "source": "n9_1",
            "target": "n5_1",
            "value": 17.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n6_1",
            "source": "n9_1",
            "target": "n6_1",
            "value": 21.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n7_1",
            "source": "n9_1",
            "target": "n7_1",
            "value": 16.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n8_1",
            "source": "n9_1",
            "target": "n8_1",
            "value": 37.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n10_1",
            "source": "n9_1",
            "target": "n10_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n11_1",
            "source": "n9_1",
            "target": "n11_1",
            "value": 4.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_1-n12_1",
            "source": "n9_1",
            "target": "n12_1",
            "value": 23.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n1_1",
            "source": "n10_1",
            "target": "n1_1",
            "value": 21.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n2_1",
            "source": "n10_1",
            "target": "n2_1",
            "value": 68.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n3_1",
            "source": "n10_1",
            "target": "n3_1",
            "value": 26.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n4_1",
            "source": "n10_1",
            "target": "n4_1",
            "value": 23.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n5_1",
            "source": "n10_1",
            "target": "n5_1",
            "value": 18.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n6_1",
            "source": "n10_1",
            "target": "n6_1",
            "value": 20.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n7_1",
            "source": "n10_1",
            "target": "n7_1",
            "value": 22.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n8_1",
            "source": "n10_1",
            "target": "n8_1",
            "value": 41.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n9_1",
            "source": "n10_1",
            "target": "n9_1",
            "value": 11.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n11_1",
            "source": "n10_1",
            "target": "n11_1",
            "value": 46.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_1-n12_1",
            "source": "n10_1",
            "target": "n12_1",
            "value": 50.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n1_1",
            "source": "n11_1",
            "target": "n1_1",
            "value": 4.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n2_1",
            "source": "n11_1",
            "target": "n2_1",
            "value": 61.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n3_1",
            "source": "n11_1",
            "target": "n3_1",
            "value": 57.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n4_1",
            "source": "n11_1",
            "target": "n4_1",
            "value": 23.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n5_1",
            "source": "n11_1",
            "target": "n5_1",
            "value": 10.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n6_1",
            "source": "n11_1",
            "target": "n6_1",
            "value": 10.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n7_1",
            "source": "n11_1",
            "target": "n7_1",
            "value": 8.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n8_1",
            "source": "n11_1",
            "target": "n8_1",
            "value": 20.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n9_1",
            "source": "n11_1",
            "target": "n9_1",
            "value": 10.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n10_1",
            "source": "n11_1",
            "target": "n10_1",
            "value": 54.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_1-n12_1",
            "source": "n11_1",
            "target": "n12_1",
            "value": 36.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n1_1",
            "source": "n12_1",
            "target": "n1_1",
            "value": 8.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n2_1",
            "source": "n12_1",
            "target": "n2_1",
            "value": 61.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n3_1",
            "source": "n12_1",
            "target": "n3_1",
            "value": 17.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n4_1",
            "source": "n12_1",
            "target": "n4_1",
            "value": 47.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n5_1",
            "source": "n12_1",
            "target": "n5_1",
            "value": 26.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n6_1",
            "source": "n12_1",
            "target": "n6_1",
            "value": 26.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n7_1",
            "source": "n12_1",
            "target": "n7_1",
            "value": 17.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n8_1",
            "source": "n12_1",
            "target": "n8_1",
            "value": 29.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n9_1",
            "source": "n12_1",
            "target": "n9_1",
            "value": 9.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n10_1",
            "source": "n12_1",
            "target": "n10_1",
            "value": 42.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_1-n11_1",
            "source": "n12_1",
            "target": "n11_1",
            "value": 22.0,
            "onlyStrana": true,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n2_0",
            "source": "n1_0",
            "target": "n2_0",
            "value": 6.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n3_0",
            "source": "n1_0",
            "target": "n3_0",
            "value": 6.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n4_0",
            "source": "n1_0",
            "target": "n4_0",
            "value": 64.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n5_0",
            "source": "n1_0",
            "target": "n5_0",
            "value": 25.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n6_0",
            "source": "n1_0",
            "target": "n6_0",
            "value": 14.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n7_0",
            "source": "n1_0",
            "target": "n7_0",
            "value": 69.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n8_0",
            "source": "n1_0",
            "target": "n8_0",
            "value": 39.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n9_0",
            "source": "n1_0",
            "target": "n9_0",
            "value": 15.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n10_0",
            "source": "n1_0",
            "target": "n10_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n11_0",
            "source": "n1_0",
            "target": "n11_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n1_0-n12_0",
            "source": "n1_0",
            "target": "n12_0",
            "value": 13.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n1_0",
            "source": "n2_0",
            "target": "n1_0",
            "value": 6.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n3_0",
            "source": "n2_0",
            "target": "n3_0",
            "value": 30.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n4_0",
            "source": "n2_0",
            "target": "n4_0",
            "value": 25.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n5_0",
            "source": "n2_0",
            "target": "n5_0",
            "value": 8.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n6_0",
            "source": "n2_0",
            "target": "n6_0",
            "value": 15.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n7_0",
            "source": "n2_0",
            "target": "n7_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n8_0",
            "source": "n2_0",
            "target": "n8_0",
            "value": 19.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n9_0",
            "source": "n2_0",
            "target": "n9_0",
            "value": 8.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n10_0",
            "source": "n2_0",
            "target": "n10_0",
            "value": 56.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n11_0",
            "source": "n2_0",
            "target": "n11_0",
            "value": 51.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n2_0-n12_0",
            "source": "n2_0",
            "target": "n12_0",
            "value": 68.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n1_0",
            "source": "n3_0",
            "target": "n1_0",
            "value": 31.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n2_0",
            "source": "n3_0",
            "target": "n2_0",
            "value": 47.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n4_0",
            "source": "n3_0",
            "target": "n4_0",
            "value": 31.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n5_0",
            "source": "n3_0",
            "target": "n5_0",
            "value": 20.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n6_0",
            "source": "n3_0",
            "target": "n6_0",
            "value": 17.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n7_0",
            "source": "n3_0",
            "target": "n7_0",
            "value": 25.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n8_0",
            "source": "n3_0",
            "target": "n8_0",
            "value": 23.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n9_0",
            "source": "n3_0",
            "target": "n9_0",
            "value": 14.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n10_0",
            "source": "n3_0",
            "target": "n10_0",
            "value": 36.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n11_0",
            "source": "n3_0",
            "target": "n11_0",
            "value": 58.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n3_0-n12_0",
            "source": "n3_0",
            "target": "n12_0",
            "value": 34.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n1_0",
            "source": "n4_0",
            "target": "n1_0",
            "value": 45.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n2_0",
            "source": "n4_0",
            "target": "n2_0",
            "value": 30.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n3_0",
            "source": "n4_0",
            "target": "n3_0",
            "value": 18.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n5_0",
            "source": "n4_0",
            "target": "n5_0",
            "value": 29.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n6_0",
            "source": "n4_0",
            "target": "n6_0",
            "value": 38.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n7_0",
            "source": "n4_0",
            "target": "n7_0",
            "value": 48.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n8_0",
            "source": "n4_0",
            "target": "n8_0",
            "value": 38.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n9_0",
            "source": "n4_0",
            "target": "n9_0",
            "value": 22.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n10_0",
            "source": "n4_0",
            "target": "n10_0",
            "value": 29.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n11_0",
            "source": "n4_0",
            "target": "n11_0",
            "value": 19.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n4_0-n12_0",
            "source": "n4_0",
            "target": "n12_0",
            "value": 54.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n1_0",
            "source": "n5_0",
            "target": "n1_0",
            "value": 22.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n2_0",
            "source": "n5_0",
            "target": "n2_0",
            "value": 3.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n3_0",
            "source": "n5_0",
            "target": "n3_0",
            "value": 1.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n4_0",
            "source": "n5_0",
            "target": "n4_0",
            "value": 18.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n6_0",
            "source": "n5_0",
            "target": "n6_0",
            "value": 60.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n7_0",
            "source": "n5_0",
            "target": "n7_0",
            "value": 41.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n8_0",
            "source": "n5_0",
            "target": "n8_0",
            "value": 25.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n9_0",
            "source": "n5_0",
            "target": "n9_0",
            "value": 13.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n10_0",
            "source": "n5_0",
            "target": "n10_0",
            "value": 5.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n11_0",
            "source": "n5_0",
            "target": "n11_0",
            "value": 3.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n5_0-n12_0",
            "source": "n5_0",
            "target": "n12_0",
            "value": 17.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n1_0",
            "source": "n6_0",
            "target": "n1_0",
            "value": 24.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n2_0",
            "source": "n6_0",
            "target": "n2_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n3_0",
            "source": "n6_0",
            "target": "n3_0",
            "value": 7.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n4_0",
            "source": "n6_0",
            "target": "n4_0",
            "value": 33.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n5_0",
            "source": "n6_0",
            "target": "n5_0",
            "value": 64.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n7_0",
            "source": "n6_0",
            "target": "n7_0",
            "value": 43.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n8_0",
            "source": "n6_0",
            "target": "n8_0",
            "value": 23.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n9_0",
            "source": "n6_0",
            "target": "n9_0",
            "value": 8.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n10_0",
            "source": "n6_0",
            "target": "n10_0",
            "value": 15.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n11_0",
            "source": "n6_0",
            "target": "n11_0",
            "value": 8.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n6_0-n12_0",
            "source": "n6_0",
            "target": "n12_0",
            "value": 43.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n1_0",
            "source": "n7_0",
            "target": "n1_0",
            "value": 72.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n2_0",
            "source": "n7_0",
            "target": "n2_0",
            "value": 30.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n3_0",
            "source": "n7_0",
            "target": "n3_0",
            "value": 16.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n4_0",
            "source": "n7_0",
            "target": "n4_0",
            "value": 47.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n5_0",
            "source": "n7_0",
            "target": "n5_0",
            "value": 56.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n6_0",
            "source": "n7_0",
            "target": "n6_0",
            "value": 47.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n8_0",
            "source": "n7_0",
            "target": "n8_0",
            "value": 42.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n9_0",
            "source": "n7_0",
            "target": "n9_0",
            "value": 12.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n10_0",
            "source": "n7_0",
            "target": "n10_0",
            "value": 44.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n11_0",
            "source": "n7_0",
            "target": "n11_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n7_0-n12_0",
            "source": "n7_0",
            "target": "n12_0",
            "value": 47.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n1_0",
            "source": "n8_0",
            "target": "n1_0",
            "value": 42.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n2_0",
            "source": "n8_0",
            "target": "n2_0",
            "value": 14.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n3_0",
            "source": "n8_0",
            "target": "n3_0",
            "value": 8.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n4_0",
            "source": "n8_0",
            "target": "n4_0",
            "value": 35.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n5_0",
            "source": "n8_0",
            "target": "n5_0",
            "value": 21.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n6_0",
            "source": "n8_0",
            "target": "n6_0",
            "value": 22.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n7_0",
            "source": "n8_0",
            "target": "n7_0",
            "value": 42.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n9_0",
            "source": "n8_0",
            "target": "n9_0",
            "value": 10.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n10_0",
            "source": "n8_0",
            "target": "n10_0",
            "value": 26.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n11_0",
            "source": "n8_0",
            "target": "n11_0",
            "value": 10.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n8_0-n12_0",
            "source": "n8_0",
            "target": "n12_0",
            "value": 22.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n1_0",
            "source": "n9_0",
            "target": "n1_0",
            "value": 22.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n2_0",
            "source": "n9_0",
            "target": "n2_0",
            "value": 24.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n3_0",
            "source": "n9_0",
            "target": "n3_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n4_0",
            "source": "n9_0",
            "target": "n4_0",
            "value": 23.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n5_0",
            "source": "n9_0",
            "target": "n5_0",
            "value": 17.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n6_0",
            "source": "n9_0",
            "target": "n6_0",
            "value": 21.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n7_0",
            "source": "n9_0",
            "target": "n7_0",
            "value": 16.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n8_0",
            "source": "n9_0",
            "target": "n8_0",
            "value": 37.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n10_0",
            "source": "n9_0",
            "target": "n10_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n11_0",
            "source": "n9_0",
            "target": "n11_0",
            "value": 4.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n9_0-n12_0",
            "source": "n9_0",
            "target": "n12_0",
            "value": 23.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n1_0",
            "source": "n10_0",
            "target": "n1_0",
            "value": 21.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n2_0",
            "source": "n10_0",
            "target": "n2_0",
            "value": 68.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n3_0",
            "source": "n10_0",
            "target": "n3_0",
            "value": 26.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n4_0",
            "source": "n10_0",
            "target": "n4_0",
            "value": 23.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n5_0",
            "source": "n10_0",
            "target": "n5_0",
            "value": 18.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n6_0",
            "source": "n10_0",
            "target": "n6_0",
            "value": 20.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n7_0",
            "source": "n10_0",
            "target": "n7_0",
            "value": 22.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n8_0",
            "source": "n10_0",
            "target": "n8_0",
            "value": 41.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n9_0",
            "source": "n10_0",
            "target": "n9_0",
            "value": 11.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n11_0",
            "source": "n10_0",
            "target": "n11_0",
            "value": 46.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n10_0-n12_0",
            "source": "n10_0",
            "target": "n12_0",
            "value": 50.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n1_0",
            "source": "n11_0",
            "target": "n1_0",
            "value": 4.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n2_0",
            "source": "n11_0",
            "target": "n2_0",
            "value": 61.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n3_0",
            "source": "n11_0",
            "target": "n3_0",
            "value": 57.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n4_0",
            "source": "n11_0",
            "target": "n4_0",
            "value": 23.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n5_0",
            "source": "n11_0",
            "target": "n5_0",
            "value": 10.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n6_0",
            "source": "n11_0",
            "target": "n6_0",
            "value": 10.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n7_0",
            "source": "n11_0",
            "target": "n7_0",
            "value": 8.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n8_0",
            "source": "n11_0",
            "target": "n8_0",
            "value": 20.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n9_0",
            "source": "n11_0",
            "target": "n9_0",
            "value": 10.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n10_0",
            "source": "n11_0",
            "target": "n10_0",
            "value": 54.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n11_0-n12_0",
            "source": "n11_0",
            "target": "n12_0",
            "value": 36.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n1_0",
            "source": "n12_0",
            "target": "n1_0",
            "value": 8.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n2_0",
            "source": "n12_0",
            "target": "n2_0",
            "value": 61.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n3_0",
            "source": "n12_0",
            "target": "n3_0",
            "value": 17.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n4_0",
            "source": "n12_0",
            "target": "n4_0",
            "value": 47.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n5_0",
            "source": "n12_0",
            "target": "n5_0",
            "value": 26.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n6_0",
            "source": "n12_0",
            "target": "n6_0",
            "value": 26.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n7_0",
            "source": "n12_0",
            "target": "n7_0",
            "value": 17.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n8_0",
            "source": "n12_0",
            "target": "n8_0",
            "value": 29.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n9_0",
            "source": "n12_0",
            "target": "n9_0",
            "value": 9.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n10_0",
            "source": "n12_0",
            "target": "n10_0",
            "value": 42.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    },
    {
        "group": "edges",
        "data": {
            "id": "n12_0-n11_0",
            "source": "n12_0",
            "target": "n11_0",
            "value": 22.0,
            "onlyStrana": false,
            "toHide": 0
        },
        "pannable": true
    }
]

data.getNodes = (()=> {
    
    return JSON.parse(JSON.stringify(data.nodes));
});
data.getEdges = (()=>{
    return JSON.parse(JSON.stringify(data.edges));
});

export default data;