<template>
    <v-row>
      <v-col>

      <v-row id="filter-row-container" class="position-relative">
        <!--<div class="md-layout-item">
          <button class="btn btn-lg btn-light btn-block" @click="resetFilters">Reset filtrov</button>
        </div>
        
        
        
        -->
        <v-col md=4 cols=6  class="position-relative mb-5 mb-md-0" v-if="false">
            <span v-if="onlyStrana">Len strany</span> <span v-if="!onlyStrana">Koalície + strany</span> <v-switch class="switchSmall row-reverse"
                v-model="onlyStrana"
                @change="changedInput"
                inset dense
                 hide-details
                color="success"
              ></v-switch>
        </v-col>
        <v-col md=4 cols=6 class="position-relative mb-5 mb-md-0">
            Rozsah preferencií {{ this.value }}<v-range-slider
              v-model="value"
              @change="changedInput"
              step="1"
              min="0"
              max="100"
            ></v-range-slider>
        </v-col>
        <v-col md=2 cols=6 class="position-relative mb-5 mb-md-0 text-center">
            <v-btn @click="filtering">Vycentrovať pohlad</v-btn>
        </v-col>
        <v-col md=2 cols=6 class="position-relative mb-5 mb-md-0 text-center">
            <v-btn  :class="{'d-none':!exportVisible}" @click="saveImg(false)">Export výsek PNG</v-btn>
        </v-col>
        <v-col md=2 cols=6 class="position-relative mb-5 mb-md-0 text-center">
            <v-btn  :class="{'d-none':!exportVisible}" @click="saveImg(true)">Export celé PNG</v-btn>
        </v-col>
        
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
//import "@/assets/vue-select.css";
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import filters from "@/filters/filters.ts";

export default {

  data() {
    return {
      exportVisible:true,
      onlyStrana:false,
      value:[50,100],
      
      filtersdata:filters,
      

      filters: [{
        nameAll: 'Vybrať všetky',
        nameNotAll: 'Zrušiť výber',
        func() {
          return true;
        },
      }],
      options: {
        multi: true,
        groups: true,
        labelName: 'label',
        labelList: 'elements',
        groupName: 'title',
        cssSelected: option => (option.selected ? { 'background-color': 'rgb(152,0,12)',color:"white" } : ''),
      },
    };
  },
  computed:{
    anyFilter:{
      cache:false,
      get(){
        return this.selectedS1a.length > 0
            ||  this.selectedDlhodobaOblast.length > 0
            ||  this.selectedRola.length > 0
            ||  this.selectedDopad.length > 0
            ||  this.selectedLokalita.length > 0
            ||  this.selectedPohlavie.length > 0
            ||  this.selectedVek.length > 0
      }
    }
  },
  mounted(){

      this.resetFilters();
      this.$root.$on("hide-export",async ()=>{
        this.exportVisible = false
      })
      this.$root.$on("show-export",async ()=>{
        this.exportVisible = true
      })
  },
  methods: {
    changeStatus(event) {
        event
        //event.stopPropagation()
    },
    closeFilter(){
      this.$root.$emit("filter-close",true);
    },
    changedInput(){
      this.filtering()
    },
    resetFilters(){
      this.onlyStrana = false;
      this.value = [50,100];
      this.filtering();
    },

    scrollToBottom(inputRef) {
      this.$nextTick(function () {
        this.$nextTick(function () {
            inputRef
        });
      });
    },
    filtering(){
      
      let filtered = {
        //"S1a":this.selectedS1a,
        //"dlhodobaOblast":this.selectedDlhodobaOblast
        onlyStrana:this.onlyStrana,
        value:this.value,

      }
      
      //console.log(filtered)

      this.$root.$emit('filter-persons', filtered)
    },
    saveImg(full){
      this.$root.$emit('save-image',{
        "full":full,
        "range":this.value
      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.position-relative{
  position:relative;
}
.select>button{
  width:100%!important;
}
.checkboxLayer.show{
  width:100%!important;
}
.btn-select {
  width:100%;
}
.v-input.switchSmall{
  margin-top:5px;
}
.v-input.switchSmall .v-label{
  font-size:12px!important;
  line-height:12px!important;
  margin:0;
}
.v-menu__content.menuable__content__active{
  z-index:9999999!important;
}
</style>
    
<style lang="scss">
.v-menu__content{
  margin-top:54px;

}
/*.mobile .v-menu__content {
   margin-top: 40px;
}*/
  .cut-text-select{
    &.onlybottomborder fieldset{
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 0;
    }
    
    .v-select__selections{
        flex-wrap: nowrap;
        span{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
    }
  }
  .select-all-checkbox{
    
    width: 100%;
    display: inline-block;
    margin:0;
    .v-input--selection-controls__input{
      margin-right: 32px;
    }
    & .v-input__control > .v-messages{
      height:0;
      min-height:0;
    }
    .v-input__slot{
      margin:0;
      label{
        font-size: 0.8125rem;
        font-weight: 500;
        line-height: 1rem;
        margin:0;
        color:#545454!important;
      }
    }
    /*&.v-input--is-label-active{
      label{
        color: #B11116!important;
      }
    }*/
  }

  .v-list.v-list--dense {
    .v-list-item{
      .v-list-item__title{
        color:#545454!important;
      }
    }
    .v-list-item.primary--text{
      .v-list-item__title{
        color:#B11116!important;
      }
    }
  }
  .v-input.switchSmall.row-reverse{
    .v-input--selection-controls__input{
      margin-right:0;
    }
    .v-input__slot{
      flex-direction: row-reverse;
    }
  }
  .v-input.switchSmall.v-input--is-label-active.success--text{
    .v-input--switch__thumb{
      color:white!important;
    }
    .v-input--switch__track{
      color:#34c759!important;
      opacity: 1 !important;
    }

  }
</style>