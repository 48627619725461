<template>
  <div v-resize="setMinHeight" class="node-graph" ref="node-graph" style="position:relative;">
    <v-container fluid>
      <v-row>
        <v-col :md="mdGraph">
          <cytoscape ref="cy" :config="config" :preConfig="preConfig" :afterCreated="afterCreated" :style="{ minHeight:wHeight+'px' }">
            <cy-element
              v-for="def in elements"
              :key="`${def.data.id}`"
              :definition="def"
            />
            <!--@click="clickNode"-->
          </cytoscape>
        </v-col>
        
      </v-row>
    </v-container>
  </div>
</template>

<script>
//import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import cola from 'cytoscape-cola';
import fcose from 'cytoscape-fcose';
import cise from 'cytoscape-cise';
import spread from 'cytoscape-spread';
import springy from 'cytoscape-springy';
import popper from 'cytoscape-popper';
import coseBilkent from 'cytoscape-cose-bilkent';
import {Resize} from "vuetify/lib/directives/resize"
import svg from 'cytoscape-svg';
let saveSVG = false;

import { saveAs } from 'file-saver';

//import noOverlap from 'cytoscape-no-overlap';
//import cyforcelayout from "cytoscape-ngraph.forcelayout/src/index";
import data from "@/data/data.ts";


/*function generateRandomInteger(min, max) {
  return Math.floor(min + Math.random()*(max + 1 - min))
}*/



//var personSVG = '<svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="person fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-person-fill mx-auto b-icon bi"><g data-v-41be6633=""><path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path></g></svg>'
//const personSVG_Url = encodeURI("data:image/svg+xml;utf-8," + personSVG);
var config = {
  style: [
    {
      selector: "node",
      css: {
        "font-size":1,
      }
    },
    {
      selector: "node",
      css: {
        //"background-color": "#f92411",
        //"background-image": personSVG_Url,
        "background-fit":"contain",
        "shape":"circle",
        //"background-size": "20px 20px",
        //"border-radius":"0px",
        "border-color":"grey",
        "border-width":2,
        "height":"data(nodeSize)",
        "width":"data(nodeSize)",

        //'text-valign': 'bottom',
        //"text-background-opacity": 0.5,
        "color": "#000",

      }
    },
    {
      selector: "node[img]",
      css: {
        "background-image": "data(img)",
      }
    },
    {
      selector: "node.koalicia",
      css: {
        "label": "data(label)",
      }
    },
    {
      selector: "node[toHide=1]",
      css: {
        "display":"none",
      }
    },
    {
      selector: "edge[toHide=1]",
      css: {
        "display":"none",
      }
    },
    {
      selector: "edge",
      css: {
        /*"line-color": "#aaa",*/
        'mid-target-arrow-shape': 'triangle',
        
        //"control-point-step-size":50000,
        /*"control-point-distances": [40, -40],
        "control-point-weights": [0.250, 0.75],*/
        'width':20,
        "arrow-scale":5,
      },
      style: {
        /*"line-color": "#aaa",*/
        'mid-target-arrow-shape': 'triangle',
        
        //"control-point-step-size":500,
        'width':20,
        "arrow-scale":5,
      }
    },
   
  ],
  layout: {
    //name:"concentric",
    //name: 'cose-bilkent',
    
    //name:"preset",
    /*name:"cose-bilkent",
    animate: false,
    idealEdgeLength: 200,
    quality: "proof",
    randomize: true,
    nodeDimensionsIncludeLabels: true,
    nodeRepulsion: 1e300,
    edgeElasticity: 0.95,//0.45
    nestingFactor: 0.1,
    //numIter: 30000,
    gravity: 0.25,
    tile: true,*/
    /*name: 'cose',
        idealEdgeLength: 200,
        nodeOverlap: 20,
        refresh: 20,
        fit: true,
        padding: 30,
        randomize: false,
        componentSpacing: 100,
        nodeRepulsion: 1e300,
        edgeElasticity: 100,
        nestingFactor: 5,
        gravity: 80,
        numIter: 1000,
        initialTemp: 200,
        coolingFactor: 0.95,
        minTemp: 1.0*/
    //name:"breadthfirst",
    //nodeDimensionsIncludeLabels: true,
    /*idealEdgeLength: 200,
    quality:"proof",
    nodeDimensionsIncludeLabels:true,
    tile: true,
    // Type of layout animation. The option set is {'during', 'end', false}
    animate: 'end',
    // Duration for animate:end
    animationDuration: 1000,
    // Amount of vertical space to put between degree zero nodes during tiling (can also be a function)
    tilingPaddingVertical: 500,
    // Amount of horizontal space to put between degree zero nodes during tiling (can also be a function)
    tilingPaddingHorizontal: 300,
    // Gravity range (constant) for compounds
    gravityRangeCompound: 0.6,
    // Gravity force (constant) for compounds
    gravityCompound: 10,
    // Gravity range (constant)
    gravityRange: 10,
    // Initial cooling factor for incremental layout
    initialEnergyOnIncremental: 5,*/
    name:"preset",
    /*name: 'cola',
    animate:true,
    //refresh: 20,
    nodeDimensionsIncludeLabels: true,
    centerGraph: true,
    nodeSpacing: 100,
    convergenceThreshold: 0.03,
    maxSimulationTime:4000,
    //edgeLength:200,
    //edgeLengthVal: 200,
    /*boundingBox: {x1:0,y1:0,w:32000,h:18000}, /*
    
    
    //flow: { axis: 'x', minSeparation: 1000 },
    /*
    name: 'fcose',
    randomize:false,
    animate:true,
    quality: "proof",
    idealEdgeLength: 100,
    fit: true, 
    nodeDimensionsIncludeLabels: true,
    //packComponents: true,
    // Whether or not simple nodes (non-compound nodes) are of uniform dimensions
    uniformNodeDimensions: true,

    animationDuration: 5000, 
    nodeRepulsion: 1e16,
    //idealEdgeLength: 500,
    edgeElasticity:  100,
    tile: true,  
    // Represents the amount of the vertical space to put between the zero degree members during the tiling operation(can also be a function)
    tilingPaddingVertical: 400,
    // Represents the amount of the horizontal space to put between the zero degree members during the tiling operation(can also be a function)
    tilingPaddingHorizontal: 400,
    // Gravity force (constant)
    gravity: 1,
    // Gravity range (constant) for compounds
    gravityRangeCompound: 1.5,
    // Gravity force (constant) for compounds
    gravityCompound: 1.0,
    // Gravity range (constant)
    gravityRange: 5, 
    // Initial cooling factor for incremental layout  
    initialEnergyOnIncremental: 1,
    nodeOverlap         : -1e4,*/
    /*name: 'cose',
        // Node repulsion (non overlapping) multiplier
    nodeRepulsion       : 400000,
    // Node repulsion (overlapping) multiplier
    nodeOverlap         : 2000,
    // Ideal edge (non nested) length
    idealEdgeLength     : 10,
    // Divisor to compute edge forces
    edgeElasticity      : 1000,
    // Nesting factor (multiplier) to compute ideal edge length for nested edges
    nestingFactor       : 5, 
    // Gravity force (constant)
    gravity             : 100, 
    // Maximum number of iterations to perform
    numIter             : 100,
    // Initial temperature (maximum node displacement)
    initialTemp         : 200,
    // Cooling factor (how the temperature is reduced between consecutive iterations
    coolingFactor       : 0.95, 
    // Lower temperature threshold (below this point the layout will end)
    minTemp             : 1.0,*/
    /*idealEdgeLength: 100,
    nodeDimensionsIncludeLabels: true,
    nodeSeparation: 1500,
    // Whether or not simple nodes (non-compound nodes) are of uniform dimensions
    uniformNodeDimensions: false,*/
    /*name:"dagre",
    avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
    avoidOverlapPadding: 10, // extra spacing around nodes when avoidOverlap: true
    nodeDimensionsIncludeLabels: true, // Excludes the label when calculating node bounding boxes for the layout algorithm
    */
    //randomize:false,
    //spacingFactor: 12,
    //clusters: []
    //name:"concentric"
    //name:"cola",
    //nodeSpacing: function( node ){node; return 0; }, // extra spacing around nodes
    
    
  },
  wheelSensitivity:0.2
}

if( navigator.userAgent.indexOf("Firefox") > -1){
  config.style.forEach((el,index)=>{
    //console.log(el,index)
    if(Object.prototype.hasOwnProperty.call(el.css, "mid-target-arrow-color")){
      config.style[index]
      delete config.style[index].css["mid-target-arrow-color"];

    }
    if(Object.prototype.hasOwnProperty.call(el.css, 'mid-target-arrow-shape')){
      delete config.style[index].css['mid-target-arrow-shape'];
      try{
        delete config.style[index].style['mid-target-arrow-shape'];
      }catch(e){
        e
      }

    }
    if(Object.prototype.hasOwnProperty.call(el.css, "arrow-scale")){
      delete config.style[index].css["arrow-scale"];
      try{
        delete config.style[index].style["arrow-scale"];
      }catch(e){
        e
      }
      

    }
  })
}


/*function deepClone(value){
      return JSON.parse(JSON.stringify(value));
}*/
//config["elements"] = elements
export default {

  directives:{
    resize:Resize
  },
  data() {
    return {
      
      elements:[],
      config:config,
      filters:{},
      wHeight:0,
      pop:null,
      person:null,
      cyW:0,
      cyH:0,
      cyinstance:null,
      popClass:"d-none",
      mdGraph:12,
      mdPop:0,
      isMobile: false,
      scrollPos:0,
      colorbutton:"white",
    };
  },
  mounted(){
     this.wHeight = window.innerHeight - 5;
     this.pop = null
     
     
     //window.addEventListener('resize', this.setMinHeight);
     this.setMinHeight();

    setTimeout(()=>{
      this.setMinHeight();
    },500)

    
     
  },/*
  unmounted() {
    window.removeEventListener('resize', this.setMinHeight);
  },*/
  methods: {
    
    setMinHeight(){
      //return;
      var filterSelection = document.getElementById("filter-selection");
      var networkPart = document.getElementById("network-part");
      
      //console.log(top.clientHeight)
      
    
      this.$root.$emit('ismobile', false)
      
      document.body.style.height = window.innerHeight+"px";
      let topHeight = filterSelection.clientHeight;
      //let filterRow = document.getElementById("filter-row");
      /*let searchRow = document.getElementById("search-row");
      searchRow.firstChild.firstChild.style.maxHeight = (window.innerHeight - filterRow.clientHeight) + "px"
      searchRow.firstChild.style.height="100%";*/
      let h = (window.innerHeight - topHeight);
      //console.log(window.innerHeight , topHeight)
      //filterSelection.style.maxHeight = window.innerHeight + "px";
      networkPart.style.minHeight = (h-12) + "px";
      let cytoscapeDiv = document.getElementById("cytoscape-div");
      
      
      
      let mapContainer = document.getElementById("map-container");
      mapContainer.style.top = 0;

      
      let cytoscapeCanvas = cytoscapeDiv.firstChild;
      cytoscapeDiv.style.minHeight = (h-12) + "px";
      cytoscapeCanvas.style.height = (h-12) + "px";

      let cy = this.$refs.cy.instance;
      
      if(cy!=null){
        //console.log("this.cyinstance",cy)
        this.$nextTick(()=>{
          this.$nextTick(()=>{
            //cy.resize();
            //cy.fit(cy.$("node"),50);
          })
        })
        
      }
      
    },
    async clickNode(ev){

      //console.log("node",ev.target.data())
      this.$root.$emit('clicked-node', ev.target.data())
    },
    getEdges(){
      
      return data.getEdges().filter((el) =>{
        return el.data.source != el.data.target
      });
    },
    getNodes(){
      
      return data.getNodes();
    },
    emitCurrentFiltered(){
      var list_ids = []
      
      this.elements.forEach((el)=>{
        
        if((!el.data.toHide || typeof(el.data.toHide)=="undefined") && el.data.id.indexOf("-") == -1){
          list_ids.push(el.data.id)
        }
      })
      
      this.$root.$emit('selected-nodes', list_ids)
      this.$nextTick(()=>{
        this.$nextTick(()=>{
          //this.hideEdges();
        })
      })
    },
    addNode(event) {
      //console.log(event.target, this.$refs.cy.instance);
      if (event.target === this.$refs.cy.instance){
        1
      }
        //console.log("adding node", event.target);
    },
    deleteNode(id) {
      //console.log("node clicked", id);
      id

    },
    updateNode(event) {
      //console.log("right click node", event);
      event
    },
    preConfig(cytoscape) {
      //this.setMinHeight();
      //cyforcelayout(cytoscape);
      //document.fonts.load
      
      
      //console.log("calling pre-config", cytoscape);
      cytoscape.use( svg );
      cytoscape.use( cola );
      cytoscape.use( fcose );
      cytoscape.use( cise );
      cytoscape.use( spread );
      cytoscape.use( springy );
      //cytoscape.use( noOverlap  );
      
      cytoscape.use( coseBilkent );
      cytoscape.use( dagre );
      //console.log(cyforcelayout)
      //cytoscape.use(cyforcelayout);
     /* try{
        1
        cyforcelayout( cytoscape );
      }catch(e){
        console.log("cyforcelayout error",e)
      }*/
      try{
        cytoscape.use(popper);
      }catch(e){
        e
      }//console.log(cise)
      
      
      
    },
    async afterCreated(cy) {
      this.setMinHeight();
      //console.log("after created", cy,this);
      
      //console.log(cy.width(),cy.height())
      //this.cyinstance = cy;
      this.$nextTick(()=>{
        this.$nextTick(()=>{
          
 
          //console.log(this.config.layout.name + " layout")
          
          this.config.layout.stop = function(){
            cy.fit(cy.nodes(),50);
            /*if(document.getElementById("app").className.indexOf("mobile") > -1){
              cy.fit(cy.nodes(),5);
            }else{
              cy.fit(cy.nodes(),50);
            }
            
            cy.minZoom(cy.zoom());*/
            //console.log("zoomlevels",cy.zoom(),cy.minZoom(),cy.maxZoom())
            //cy.svg()
            /*
            let exportedData = cy.json(true).elements.filter((it)=>{
              return it.group == "nodes"
            }).map((it)=>{
              return {
                "id":it.data.id,
                "position":it.position
              }
            });
            console.log(exportedData)
            let blobJSON = new Blob([JSON.stringify(exportedData)], {type:"text/json;charset=utf-8"});
            saveAs(blobJSON, "pos.json")*/
            saveSVG =  0
            if(saveSVG){
              var svgContent = cy.svg({scale: 1, full: true,bg:"#FFFFFF"});
              var blob = new Blob([svgContent], {type:"image/svg+xml;charset=utf-8"});
              saveAs(blob, "demo.svg")
            }
          }
          
          cy.layout(this.config.layout).run()
          setTimeout(()=>{

            
            cy.fit(cy.nodes(),50);
            
            cy.maxZoom(0.36886688234636195);
            
          },1000)
          this.$root.$on("save-image",async (inOptions)=>{
              var full = inOptions["full"]
              var filenameadd = inOptions["range"][0] + "_" + inOptions["range"][1];
              this.$root.$emit('hide-export')
              try{
                var options = {scale: 1,output:"blob-promise", full: full,bg:"#FFFFFF"};
                if(full){
                  options["scale"] = 0.5;
                }
                var content = await cy.png(options);
                //console.log(content)
                var blob = new Blob([content], {type:"image/png"});
                saveAs(blob, "pohlad_" + filenameadd +".png")
              }catch(e){
                e
              }
              this.$root.$emit('show-export')
          });
          cy.on('dragfree',(evt)=>{
            //console.log(evt.target.data().id,evt.target.position())
            evt
            return false;
          });

          cy.on('pan zoom resize',(evt)=>{
            //this.updatePopper();
            evt.preventDefault();
            //console.log(cy.zoom());
            return false;
          });
          cy.on("resize",(evt)=>{
            this.cyW = cy.width();
            this.cyH = cy.height();
            //console.log("extent",cy.extent())
            evt.preventDefault();
            return false;
          });
          

          
          this.$root.$on("filter-persons", (inp)=>{
            //console.log(inp)
            this.$nextTick(()=>{
              this.setMinHeight()
            })
            
            
            /*var idx = 0;
            var el;*/
            let edges =  this.getEdges();
            
            let nodes = this.getNodes();

            //console.log(inp,"display1")
            if("onlyStrana" in inp ){
              
              
              
              

              
                for(let i=0;i<nodes.length;i++){
                  nodes[i]["data"]["toHide"] = (!(nodes[i]["data"]["onlyStrana"] == inp["onlyStrana"])) * 1
                  /*if(nodes[i]["data"]["toHide"] == 0){
                    console.log(nodes[i]["data"])
                  }*/
                }
                

                for(let i=0;i<edges.length;i++){
                  edges[i]["data"]["toHide"] = (!(edges[i]["data"]["onlyStrana"] == inp["onlyStrana"])) * 1
                  //console.log(edges[i]["data"])
                }


              
            }
            if("value" in inp ){

                if(inp["value"].length > 0){
                  for(let i=0;i<edges.length;i++){
                    //console.log(edges[i]["data"])
                    if(edges[i]["data"]["toHide"] == 0){
                      if((inp["value"][0] <= edges[i]["data"]["value"] && edges[i]["data"]["value"] <= inp["value"][1])){
                        edges[i]["data"]["toHide"] = 0
                      }else{
                        edges[i]["data"]["toHide"] = 1
                      }
                      
                    }
                    
                  }

                }
            }
            //console.log("elements-beforecurrentfiltered",elements)
            this.elements = nodes.concat(edges);
            //console.log(this.elements)
            
            this.$nextTick(()=>{

              cy.fit(cy.nodes(),50);
            })
            
            //cy.layout(this.config.layout).run()
            
          })

          this.$root.$emit("filter-persons",{
            //"S1a":this.selectedS1a,
            //"dlhodobaOblast":this.selectedDlhodobaOblast
            onlyStrana:false,
            value:[50,100],

          })
        })
      })
    },
    async after() {
      // cy: this is the cytoscape instance
      //const cy = await this.$cytoscape.instance;
      //cy
      1
      //console.log("after created", cy,this);
      
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

#cy-pop{
  background:white;
  box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.14);
}
#cy-pop-tooltip{
  background:white;
  max-width:100%;
  width:100%;
  height:100%;
  
}

#cy-pop-tooltip .container-fluid{
  padding-left: 15px;
  padding-right: 15px;
  
}
.mobile .show-pop{
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  padding-bottom:20px;
}
/*#arrow,
#arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
  background:white;
}
#cy-pop[data-popper-placement^='top'] > #arrow {
  bottom: -6px;
}

#cy-pop[data-popper-placement^='bottom'] > #arrow {
  top: -6px;
}

#cy-pop[data-popper-placement^='left'] > #arrow {
  right: -6px;
}

#cy-pop[data-popper-placement^='right'] > #arrow {
  left: -6px;
}*/
#close{
  position:absolute;
  right:38px;
  top:12px;
  font-size:2rem;
  cursor: pointer;
  color:white;
}
</style>
