<template>
  <v-app style="overflow:hidden;">
    <!--<div >-->
      <v-container fluid id="filter-selection" >
        <v-row id="filter-row">
          <v-col>
            
            <FilterSelection />
          </v-col>
        </v-row>

      </v-container>
      
    <!--</div>-->
    <v-container fluid id="network-part" style="overflow:hidden;position:relative;">

      <v-row>

          <v-col  id="search-node-container" style="position:relative">
            
            <div  id="map-container" style="position:absolute;top:0;left:0;height:100%;width:100%;">
              <Display1 />
            </div>
            <!--<div class="container-fluid" style="background:white;position:absolute;left:0;top:0;height:100%;width:100%;z-index:99"  :class="{'d-none':!hasList}">
              <VisiblePersons />
            </div>-->
          </v-col>

          <!--<div class="col-md-2 order-md-12 order-sm-1" id="rightSide" style="z-index:99999;border-left:1px solid grey;padding-top:5px;overflow-y:scroll;overflow-x:hidden;">
            
              
            
            
              
            
          </div>-->
      </v-row>
        

    </v-container>
  </v-app>
</template>

<script>
import Display1 from './components/Display1.vue'
import FilterSelection from './components/FilterSelection.vue'

export default {
  name: 'App',
  components: {
    Display1,
    FilterSelection,
  },
  data(){
    return {
      
    }
  },
  mounted(){
    
    this.$root.$on("isSearching", (isSearching)=>{
      this.isSearching = isSearching
    })
  },
  unmounted(){

  },
  methods:{

  }
}
</script>

<style>
html{
  overflow:hidden;
  height:100%;width:100%;
}
body{
  overflow: hidden;
  height:100%;width:100%;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  height:100%;
}

.toggle-container{
  position:fixed;
  top:0px;
  left:0px;
  z-index:999;
  background:white;
  padding:5px;
  /*width:100%;*/
  
}
.toggler{
  padding:5px;
  z-index:9999;
}


.container--fluid{
  max-width:100%!important;
}
.cut-text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

</style>
<style lang="scss">


#filter-open{
  bottom:0;
  left:0;
  position:absolute;
  width:auto;
  height:auto;
  color:white;
  .desktop &{
    display:none;
  }
  .v-btn{
    border-top-right-radius: 10px;
  }
  span.v-btn__content{
    color:white;
  }
}
  // ...
</style>