import Vue from 'vue'
import App from './App.vue'
import VueCytoscape from 'vue-cytoscape'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

//import vSelect from 'vue-select'
import vueMultiSelect from 'vue-multi-select';
import VueMaterial from 'vue-material'


// Import Bootstrap an BootstrapVue CSS files (order is important)
/*import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'*/
//import cytoscape from 'cytoscape';
//import cyforcelayout from "cytoscape-ngraph.forcelayout/src/index";
import vuetify from './plugins/vuetify'
//cytoscape.use(cyforcelayout)
//cyforcelayout( cytoscape );
/*

//import cola from 'cytoscape-cola';
import fcose from 'cytoscape-fcose';
import panzoom from 'cytoscape-panzoom';
panzoom( cytoscape );
          


cytoscape.use(fcose);

import cise from 'cytoscape-cise';
cytoscape.use( cise );

import popper from 'cytoscape-popper';

cytoscape.use( popper );
*/

Vue.use(VueMaterial)


Vue.component('multi-select', vueMultiSelect)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueCytoscape)

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faResearchgate,faLinkedin,faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faResearchgate)
library.add(faLinkedin)
library.add(faLinkedinIn)


/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)




Vue.config.productionTip = false
/*function waitForFontLoad(font, timeout, interval) {
  const startTime = Date.now();

  return new Promise((resolve, reject) => {
    const recursiveFn = () => {
      const currTime = Date.now();
      console.log(document.fonts)
      if (currTime - startTime >= timeout) {
        reject("font listener timeout " + font);
      } else {
        document.fonts
          .load(font)
          .then((fonts) => {
            if (fonts.length >= 1) {
              resolve(true);
            } else {
              setTimeout(recursiveFn, interval);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    };
    recursiveFn();
  });
}
waitForFontLoad("12px pt-sans", 1000, 10)
*/
new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
